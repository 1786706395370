<template>
<div class="profile-form" v-if="postParams">
  <h2 class="profile-form__title">Thông tin cá nhân của bạn</h2>
  <!-- 名前 -->
  <label class="profile-form__label">Tên<span>*</span></label>
  <input class="profile-form__input" type="text" v-model="postParams.lastname" placeholder="Họ" />
  <input class="profile-form__input--second" type="text" v-model="postParams.firstname" placeholder="Tên" />
  <!-- 生年月日 -->
  <label class="profile-form__label">Ngày tháng năm sinh<span>*</span></label>
  <div class="profile-form__select">
    <p :class="age.length != 0 ? 'profile-form__select__area' : 'profile-form__select__area--none'"
    @click="$refs.datepicker.open()">{{ age.length === 0 ? 'Chọn' : age }}</p>
    <v-icon class="profile-form__select__arrow">expand_more</v-icon>
  </div>
  <!-- 性別 -->
  <label class="profile-form__label">Giới tính<span>*</span></label>
  <div class="profile-form__select">
    <select :class="postParams.sex.length != 0 ? 'profile-form__select__area' : 'profile-form__select__area--none'" v-model="postParams.sex">
      <option disabled value="" style='display:none;'>Chọn</option>
      <option value="Nam">Nam</option>
      <option value="Nữ">Nữ</option>
    </select>
    <v-icon class="profile-form__select__arrow">expand_more</v-icon>
  </div>
  <!-- 日本語レベル -->
  <label class="profile-form__label">Trình độ tiếng Nhật<span>*</span></label>
  <div class="profile-form__select">
    <select :class="postParams.level.length != 0 ? 'profile-form__select__area' : 'profile-form__select__area--none'" v-model="postParams.level">
      <option disabled value="" style='display:none;'>Chọn</option>
      <option value="N5">N5</option>
      <option value="N4">N4</option>
      <option value="N3">N3</option>
      <option value="N2">N2</option>
    </select>
    <v-icon class="profile-form__select__arrow">expand_more</v-icon>
  </div>
  <!-- 職業 -->
  <label class="profile-form__label">Nghề nghiệp</label>
  <div class="profile-form__select">
    <select :class="postParams.occupation.length != 0 ? 'profile-form__select__area' : 'profile-form__select__area--none'" v-model="postParams.occupation">
      <option disabled value="" style='display:none;'>Chọn</option>
      <option v-for="id in Object.keys(occupations)" :key=id :value=id>{{ occupations[id].name }}</option>
    </select>
    <v-icon class="profile-form__select__arrow">expand_more</v-icon>
  </div>
  <!-- トピック -->
  <label class="profile-form__label">Sở thích</label>
  <div class="profile-form__select">
    <select :class="postParams.topic.length != 0 ? 'profile-form__select__area' : 'profile-form__select__area--none'" v-model="postParams.topic">
      <option disabled value="" style='display:none;'>Chọn</option>
      <option v-for="id in Object.keys(topics)" :key=id :value=id>{{ topics[id].name }}</option>
    </select>
    <v-icon class="profile-form__select__arrow">expand_more</v-icon>
  </div>
  <!-- submitボタン -->
  <v-btn class="profile-form__submit" :disabled="!canSubmit" depressed @click="edit()">Lưu thông tin</v-btn>
  <!-- 日付選択 -->
  <datepicker ref="datepicker" :max="formated(new Date(), 'YYYY-MM-DD')" @setDate="setDate" />
  <!-- 処理中のコンポーネント -->
  <processing v-if="isProcessing"></processing>
</div>
</template>

<script>
import CommonFunction from '@/lib/common'
import Datepicker from '@/components/common/datepicker'
import Processing from '@/components/common/processing'

export default {
  extends: CommonFunction,
  components: { Datepicker, Processing },
  data () {
    return {
      // プロフィールの値
      postParams: null,
      // 表示用の年齢
      age: '',
      // 処理中かどうか
      isProcessing: true,
      // 更新前の職業
      beforeOccupation: ''
    }
  },
  async mounted () {
    // 職業とトピック情報の取得
    const promises = []
    // 職業情報なし、または現在の職業情報をひとつだけ持っている場合は全職業情報を取得
    if (Object.keys(this.occupations).length < 2) promises.push(this.$store.dispatch('occupation/getOccupations'))
    if (Object.keys(this.topics).length === 0) promises.push(this.$store.dispatch('topic/getTopics'))
    if (promises.length > 0) await Promise.all(promises)

    // ユーザー情報の取得
    const user = this.$store.getters['user/user']
    this.postParams = {
      firstname: user.firstname,
      lastname: user.lastname,
      age: typeof user.age === 'string' ? ''
        : user.age instanceof Date ? user.age
          : user.age.toDate(),
      sex: user.sex,
      level: user.level,
      occupation: user.occupation,
      topic: user.topic
    }
    // 職業変更検知のため変更前の職業を格納しておく
    this.beforeOccupation = user.occupation

    this.age = this.postParams.age instanceof Date ? this.formated(this.postParams.age, 'YYYY/MM/DD') : ''

    this.isProcessing = false
  },
  computed: {
    /**
     * @return {Object} 職業情報 { id: 職業のオブジェクト, ... }
     */
    occupations () {
      return this.$store.getters['occupation/occupations']
    },
    /**
     * @return {Object} トピック情報 { id: トピックのオブジェクト, ... }
     */
    topics () {
      return this.$store.getters['topic/topics']
    },
    /**
     * @return {Boolean} 必須項目の記入があるか
     */
    canSubmit () {
      // 非必須項目なもの
      const regexpOption = /occupation|topic/

      // 記入の有無のチェック
      return Object.values(this.postParams)
        .filter((value, index) => {
          // 非必須項目
          if (Object.keys(this.postParams)[index].match(regexpOption)) {
            return true
          } else {
            // 必須項目
            // 日付か文字列かつ0以上の場合はOK
            return value instanceof Date ? true : value.length > 0
          }
        }).length === Object.values(this.postParams).length
    }
  },
  methods: {
    /**
     * 日付をセット
     * @param {String} date 日付
     */
    setDate (date) {
      this.postParams.age = date ? new Date(date) : ''
      this.age = date ? this.formated(this.postParams.age, 'YYYY/MM/DD') : ''
    },
    /**
     * プロフィールの編集
     */
    edit () {
      this.isProcessing = true

      // 更新日の追加
      this.postParams.updatedAt = new Date()

      // 職業が変更されたかどうかの判定
      const isEditedOccupation = this.postParams.occupation !== this.beforeOccupation

      // 更新
      this.$store.dispatch('user/updateUser', {
        uid: this.$store.getters['user/uid'],
        param: this.postParams,
        edited: isEditedOccupation
      }).then(() => {
        this.$store.commit('setTelop', { show: true, msg: 'Cập nhật thông tin cá nhân hoàn tất', type: 'success' })
        this.$router.push({ name: 'Menu', query: { action: 'profile' } })
        this.isProcessing = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
.profile-form {
  &__title {
    font-size: 20px;
    font-weight: bold;
  }
  &__label {
    display: block;
    margin-top: 30px;
    margin-bottom: 12.5px;
    font-size: 12px;
    font-weight: bold;
    span {
      color: #ef5c6b;
    }
  }
  &__input {
    width: 100%;
    padding: 0 5px;
    font-size: 16px;
    border-bottom: 1px solid #e0e0e0;
    &--second {
      @extend .profile-form__input;
      margin-top: 20px;
    }
    &::placeholder {
      color: #a5a5a5;
    }
  }
  &__select {
      width: 100%;
      padding: 0 5px;
      font-size: 16px;
      border-bottom: 1px solid #e0e0e0;
      &__area {
          display: inline-block;
          vertical-align: middle;
          width: calc(100% - 24px);
          margin: 0;
          &--none {
              @extend .profile-form__select__area;
              color: #a5a5a5;
          }
      }
      &__arrow {
        display: inline-block;
        vertical-align: middle;
        &.theme--light.v-icon {
          color: #a5a5a5;
        }
      }
  }
  &__submit {
    width: 100%;
    margin-top: 40px;
    color: white;
    text-transform: none;
    font-size: 17px;
    border-radius: 36px;
    &.v-btn:not(.v-btn--round).v-size--default {
      height: 44px;
    }
    &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background-color: #1cc2a6;
    }
    &.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background-color: #a5a5a5 !important;
    }
    &.theme--light.v-btn.v-btn--disabled {
      color: white !important;
    }
  }
}
</style>
