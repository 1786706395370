<template>
<div class="profile-edit">
  <!-- フォーム -->
  <profile-form class="profile-edit__form" />
</div>
</template>

<script>
import ProfileForm from '@/components/profile/form'

export default {
  components: { ProfileForm }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';

.profile-edit {
  margin-top: $header_height;
  padding: 41.7px 36px;
}
</style>
